import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'ui-kit/button/button';

import IconComposer from 'display-components/icon-composer';

import './balance-by-member-list.styles.scss';

export type MemberBalance = {
    fullName: string;
    totalDue: string; // type may change on API integration
    isCaregiver: boolean; // remove after API integration
};

export interface BalanceByMemberListProps {
    members: MemberBalance[];
    onClick: () => void;
}

const getMemberInitials = (name: string) => name.replace(/\B\w+/g, '').replace(/\s/g, '');
const hasPositiveBalance = (value: string) => Number(value) > 0;

// this hurt my eyes but it's not possible to determine the API response atm
const totalDueParser = (value: string) => (Number(value) > 0 ? `+$${value}` : `$${value.replace('-', '')}`);

const BalanceByMemberList = ({ members, onClick }: BalanceByMemberListProps) => {
    const { t } = useTranslation();

    return (
        <ul className="balance-by-member-list">
            {members.map(({ isCaregiver, totalDue, fullName }) => {
                const memberIconType = classNames('balance-by-member-list__fullname-container', {
                    'fullname-is-caregiver': isCaregiver
                });
                const totalDueVariant = classNames('balance-by-member-list__item__total-due', {
                    'total-due-refund': hasPositiveBalance(totalDue),
                    'total-due-debt': !hasPositiveBalance(totalDue)
                });

                return (
                    <li className="balance-by-member-list__item" key={fullName}>
                        <div className="balance-by-member-list__item__user-info">
                            {hasPositiveBalance(totalDue) ? (
                                <IconComposer
                                    content={<p className={memberIconType}>{getMemberInitials(fullName)}</p>}
                                    subIconType="positive"
                                    variant="content"
                                />
                            ) : (
                                <p className={memberIconType}>{getMemberInitials(fullName)}</p>
                            )}
                            <div className="balance-by-member-list__item__user-info__details">
                                <p className="balance-by-member-list__item__user-info__details__full-name">
                                    {fullName}
                                </p>
                                <p className={totalDueVariant}>{totalDueParser(totalDue)}</p>
                            </div>
                        </div>
                        {!hasPositiveBalance(totalDue) && (
                            <Button
                                className="balance-by-member-list__item__pay-btn btn-bold btn-outline"
                                label={t('pages.profile.balanceByMember.btnAction')}
                                type="button"
                                variant="outline-primary"
                                onClick={onClick}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default BalanceByMemberList;
